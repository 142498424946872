<template>
  <div class="w-100 d-flex flex-column">
    <base-form class="mt-3 mr-md-3" :updateBtn="false" shadow>
      <base-form-group title="Cahier des charges" dense icon="book">
        <span class="text-muted">
          <small
            >Archivez ici votre cahier des charges et les documents
            associés.</small
          >
        </span>
        <div class="w-100">
          <uploader
            v-if="id"
            id="document_affaire_prepa"
            url="/upload/document"
            title="Cahier des charges"
            :file_system="'CRM'"
            :file_field="'CDC'"
            :file_key="id"
          ></uploader>
        </div>
      </base-form-group>
    </base-form>
  </div>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormGroup from "@/components/bases/FormGroup.vue";
import Uploader from "@/components/bases/Uploader.vue";

export default {
  components: {
    BaseForm,
    BaseFormGroup,
    Uploader
  },
  data() {
    return {};
  },
  computed: {
    ...mapFields("affaires", ["affaire.id"])
  }
};
</script>
